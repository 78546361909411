import './main.css'
import {Elm} from './Main.elm'
import * as serviceWorker from './serviceWorker'
import {
    keeperErrors,
    signerErrors,
    keeperPromise,
    loginWithKeeper,
    loginWithMetamask,
    loginWithSigner,
    onKeeperNetworkChanged,
    onKeeperUserChanged,
    reserves
} from '@vires.finance/dapp'

import * as btc from 'bitcoin-address-validation';

import * as gateway from "./Gateway/gateway"
import * as topup from "./Gateway/Topup/topup"
import {send} from "./Gateway/Send/send";
import {hashCode} from "./Common/hashCode"
import * as addressValidation from "./Shared/Waves/address"
import * as governance from "./Governance/Voting/New/Propose/propose"
import * as assetModule from "./Shared/Asset/port"
import {ethAddress2waves} from "@waves/node-api-js";


const flavour = process.env.ELM_APP_FLAVOUR ?? 'prod'

async function getConfig() {
    return await import(`./_config/${flavour}.config.json`);
}

//const config = require(`./_config/${flavour}.config.json`)
const english = require(`../resources/languages/en.json`)

const ebCommands = [/*"claimEbReward", "claimReward", */"claimAllRewardsAndAllEbAvailable2"]
const govCommands = ["withdrawAllPossibleVires", "lock", "claimProtocolProfitFrom", "setClaimDelegation", "removeClaimDelegation", "propose", "voteYes", "voteNo", "retract", "retractProposal"]
const vTokenCommands = ["mintAtoken", "replenishWithAtoken", "redeemAtokens"]
const legacyMarketsCommand = ["startVesting","convertToUSDN", "withdrawVestedAllUSDN", "vestingAllClaimRewards", "withdrawAsLP","importLP"]

const commandNames =
    [ "deposit", "supplyProtectedCollateral", "withdraw2", "repay", "collapseDebt", "repayWithAtoken",  "withdrawProtectedCollateral", "supplyUnlockedLPTokens"
    ].concat(ebCommands).concat(govCommands).concat(vTokenCommands).concat(legacyMarketsCommand)


const languageCodes = ["en", "es", "ru", "zh"];

function fixFeeAsset(params) {
    return {...params, feeAssetId: params.feeAssetId === "WAVES" ? null : params.feeAssetId};
}

const addressKey = "address" + (flavour === "prod" ? "" : "_" + flavour);

const urlParts = window.location.pathname.split("/prozor/");

const getAddress = function () {
    return (urlParts.length === 2)
        ? urlParts[1]
        : localStorage.getItem("termsAccepted") === "true" ? localStorage.getItem(addressKey) : null;
};

document.addEventListener("DOMContentLoaded", async () => {

    const config = await getConfig();

    if (urlParts.length === 2) {
        const adr = urlParts[1];
        if (adr.indexOf("0x") === 0) {
            localStorage.setItem("loginWith", "metamask");
            window.location = "/prozor/" + ethAddress2waves(adr, config.chainId.charCodeAt(0));
        }
    }

    // RaF
    const earlyBirdsReferral = "earlyBirdsReferral";

    const urlParams = new URLSearchParams(window.location.search);
    let ref = urlParams.get('ref');
    if (ref) {
        localStorage.setItem(earlyBirdsReferral, ref);
    } else {
        ref = localStorage.getItem(earlyBirdsReferral)
    }

    function preferredLanguage() {
        if (getAddress()) return languageCodes[0];
        const code2 = navigator.language.substring(0, 2).toLowerCase();

        return languageCodes.indexOf(code2) >= 0 ? code2 : languageCodes[0];
    }

    const language = localStorage.getItem("lang") ?? preferredLanguage();
    const sha = process.env.VERCEL_GIT_COMMIT_SHA ?? Date.now().toString()

    async function getLanguageAndTranslations() {
        if (language === "en") {
            return [english]
        }
        const response = await fetch("/" + pathmappings[`languages/${language}.json`])
        const translations = await response.json()
        return [translations, english];
    }

    const translations = await getLanguageAndTranslations();

    const navBarLocalStorageItem = "nav-bar-" + hashCode(english.notificationBarMD);


    const appConfig =
        {
            ...config,
            address: getAddress(),
            translations,
            language,
            pathmappings,
            sha,
            assets:
                Object.values(config.assets)
                    .map(a => {
                        return {
                            ...a,
                            leasingAPRBase: a.leasingAPRBase ?? null,
                            staking: a.staking ?? false,
                        }
                    }),
            ducksClosed: !!localStorage.getItem(navBarLocalStorageItem),
            ref,
            width: window.innerWidth,
            hidden: document.hidden,
            lock: localStorage.getItem("lockByDefault") !== "false",
            userAgent: navigator.userAgent
        }

    // console.log(appConfig.translations)

    const app = Elm.Main.init({
        node: document.getElementById('root'),
        flags: appConfig
    })

    let commands = null
    let loginResult = null;


    function error(err, action) {
        console.error(err)
        app.ports.error.send(err?.message ?? err?.toString() ?? "Unexpected error")

        gtag('event', 'Error', {action});
    }


    app.ports.login.subscribe(login)

    function getLoginPromise(loginWith) {
        switch (loginWith) {
            case "keeper":
                return loginWithKeeper({chainId: config.chainId})

            case "metamask":
                return loginWithMetamask({nodeUrl: config.nodeApi, chainId: config.chainId})

            case "email":
            case "passwordOnly":
            case "ledger":
                return loginWithSigner(loginWith)

            default:
                throw "Invalid login method: " + loginWith
        }
    }


    async function login(loginWith) {
        console.log("login with: " + loginWith)

        try {
            const login = await getLoginPromise(loginWith)
            afterLogin(login, loginWith)

            localStorage.setItem("loginWith", loginWith);
            localStorage.setItem(addressKey, login.address);

            app.ports.loggedIn.send(login.address)

            gtag('event', 'User',
                {
                    action: "login",
                    with: loginWith,
                });


        } catch (e) {
            loginError(e, loginWith)
        }
    }

    function afterLogin(login, loginWith) {
        loginResult = login
        // console.log(login);
        commands = reserves(config, loginWith === "keeper" ? login.keeper : login.signer);
    }

    function loginError(e, loginWith) {
        // console.log("Login " + +": " + e);
        let msg
        if (loginWith === "keeper") {
            if (e === keeperErrors.wrongNetwork) {
                msg = "Waves Keeper was unable to login due wrong network"
            } else if (e === keeperErrors.rejectedByUser) {
                msg = "Waves Keeper user login rejected"
            } else if (e === keeperErrors.noKeeper) {
                msg = "Please install Waves Keeper before login"
            }
            msg = "Unable to login, make sure you have at least one account in Waves Keeper."
        }

        app.ports.error.send(msg ?? "Unable to login")

        gtag('event', 'Error',
            {
                action: "login",
                with: loginWith,
            });
    }

    function loggedOut() {
        commands = null;
        loginResult = null;
        const address = getAddress();
        if (address) gateway.clearCredentials(address);

        localStorage.removeItem("loginWith");
        localStorage.removeItem(addressKey);


        app.ports.loggedOut.send(null);
    }

    onKeeperUserChanged(data => {
        if (data.newUser)
            login("keeper")
        else
            loggedOut()
    })

    onKeeperNetworkChanged(loggedOut)

    app.ports.logout.subscribe(loggedOut)


    app.ports.askKeeperInstalled.subscribe(() => {
            console.log("checking KeeperWallet")
            if (typeof KeeperWallet !== 'undefined') {
                app.ports.keeperInstalled.send(true)
            }
        }
    )

    async function loginAndContinue(action) {
        const address = getAddress();
        if (!address) return;

        if (loginResult) {
            await action();
            return;
        }

        const loginWith = localStorage.getItem("loginWith")

        try {
            const login = await getLoginPromise(loginWith);
            afterLogin(login, loginWith);
            if (address === login.address) {
                await action();
            } else {
                error("You've signed with different account", "account changed")
            }
        } catch (e) {
            loginError(e, loginWith)
        }
    }


    // dApp Write
    commandNames.forEach(c => {
        app.ports[c].subscribe(async params => {

            async function write() {
                params = fixFeeAsset(params)

                // todo: remove debug logs 
                console.log(c)
                console.log(params)

                let isEbCommand = ebCommands.includes(c);
                let isGovCommand = govCommands.includes(c);
                let isvTokenCommand = vTokenCommands.includes(c);
                const viresAssetCommand = isEbCommand || isGovCommand

                try {

                    const tx = await commands[c](params);
                    console.log(tx);
                    const amount =
                        params.displayAmount
                        ?? params.amount
                        ?? params.paymentAmount
                        ?? params.aTokensAmount
                        ?? 0;

                    const aId = params.displayAssetId ?? (viresAssetCommand ? appConfig.viresAsset.id : params.assetId )

                    const asset =
                        aId === appConfig.viresAsset.id
                            ? appConfig.viresAsset
                            : appConfig.assets.find(a => a.id === aId || a.aTokenId === params.assetId || a.reserveAddress === params.reserve);

                    let succeedJson = {
                        ...params,
                        assetId: asset.id.split("_")[0],
                        id: tx,
                        command: c,
                        amount: amount
                    };
                    console.log("succeed")
                    console.log(succeedJson)

                    app.ports.succeed.send(succeedJson)

                    const quantity = amount / (10 ** asset.decimals);
                    const value = Math.round((params.gaAmountUsd + Number.EPSILON) * 100) / 100; // todo: add for $vires
                    let marketEventData = {
                        action: c,
                        asset: asset.name,
                        amount: quantity,
                        with: localStorage.getItem("loginWith"),
                        value: value,
                        currency: "USD"
                    };
                    // console.log("market")
                    // console.log(marketEventData)

                    const eventName = isEbCommand ? "Vires" : (isGovCommand ? "Governance" : (isvTokenCommand ? "vToken" : "Markets"));

                    gtag('event', eventName, marketEventData);

                    if (c.startsWith("deposit")) {
                        let purchaseEventData = {
                            "transaction_id": hashCode(tx),
                            // "affiliation": "Google online store",
                            "value": value,
                            "currency": "USD",
                            "items": [
                                {
                                    "id": asset.id,
                                    "name": asset.name,
                                    // "list_name": "Search Results",
                                    // "brand": "Google",
                                    // "category": "Apparel/T-Shirts",
                                    // "variant": "Black",
                                    // "list_position": 1,
                                    "quantity": quantity,
                                    "price": params.gaUsdRate
                                }
                            ]
                        };
                        console.log("purchase****")
                        console.log(purchaseEventData);
                        gtag('event', 'purchase', purchaseEventData);
                    }

                } catch (e) {
                    if (e === signerErrors.authExpired) {
                        loginResult = null;
                        commands = null;

                        app.ports.notify.send("User session has expired. Please refresh the page.")

                        // setTimeout(async () => {
                        //     await loginAndContinue(write)
                        // }, 1)
                    } else {
                        error(e, c)
                    }
                }

            }

            await loginAndContinue(write)
        })
    })


    app.ports.updateCollateral.subscribe(async params => {

        async function updateCollateral() {
            const c = params.collateral ? "enable" : "disable"
            try {
                const tx = await commands[c + "UseAsCollateral"](fixFeeAsset(params))
                app.ports.updateCollateralSucceed.send({...params, id: tx})
            } catch (e) {
                error(e, "updateCollateral")
            }

        }

        await loginAndContinue(updateCollateral)
    })

    async function sendStoredGatewayCredentials(address) {
        const credentials = await gateway.getCredentials(address);
        // console.log(credentials);
        credentials && app.ports.gotGatewayCredentials.send(credentials);
    }

    app.ports.getStoredGatewayCredentials.subscribe(sendStoredGatewayCredentials)

    app.ports.authorizeGateway.subscribe(async (address) => {
        let storedAddress = loginResult?.address || getAddress();
        if (address !== storedAddress) {
            error("Account address was changed. Please re-login", "account changed");
            return;
        }

        async function getCredentials() {
            await gateway.authGateway(config.chainId, loginResult);
            await sendStoredGatewayCredentials(address)
        }

        await loginAndContinue(getCredentials);
    })


    app.ports.getEthereumWalletInstalled.subscribe(async () => {
            const isInstalled = await topup.ethereumWalletInstalled()
            app.ports.gotEthereumWalletInstalled.send(isInstalled);
        }
    )

    app.ports.getEthereumWalletBalance.subscribe(async currency => {
        try {
            const balance = await topup.getBalance(appConfig.assets, currency);
            app.ports.gotEthereumWalletBalance.send({currency, balance});
        } catch (e) {
            console.error(e)
            app.ports.gotEthereumWalletBalance.send({currency, balance: -1});
            // error("Can't get balance")
        }
    })

    app.ports.web3Deposit.subscribe(async params => {
        try {
            const tx = await topup.topup(appConfig.assets, params.platform, params.address, params.token, params.decimals, params.amountFloat);
            console.log("Gateway deposit: " + tx);
            app.ports.web3DepositSucceed.send(params);

            gtag('event', 'Gateway',
                {
                    action: 'top up',
                    platform: params.platform,
                    asset: params.token,
                    amount: params.amountFloat
                });

        } catch (e) {
            console.error(e);
            error(e, "topUp");
        }
    })

    app.ports.manualTopUp.subscribe(params => {
        gtag('event', 'Gateway',
            {
                action: 'top up',
                kind: 'manual',
                platform: params.platform,
                asset: params.token,
            });
    })

    app.ports.send.subscribe(async params => {
        console.log(params);

        async function doSend() {
            try {
                const tx = await send(config, loginResult.signer || loginResult.keeper, fixFeeAsset(params))
                console.log("Gateway withdraw: " + tx)

                app.ports.gatewayWithdrawSucceed.send(params)
                app.ports.walletBalanceChanged.send(null)

                gtag('event', 'Gateway',
                    {
                        action: 'send',
                        platform: params.platform,
                        asset: params.gaAsset,
                        amount: params.gaAmount
                    });

            } catch (e) {
                error(e, "send")
            }
        }

        await loginAndContinue(doSend);
    })

    app.ports.validateBitcoinAddress.subscribe(address => {
        const valid = btc.validate(address);
        app.ports.gotBitcoinAddressValidationResult.send({address, valid});
    })


    // copy to clipboard
    app.ports.copy.subscribe(text => {
        // console.log('copy ' + text);
        navigator.clipboard?.writeText(text);
        app.ports.copied.send(text);
    })

    app.ports.broadcast.subscribe(app.ports.broadcasted.send)

    // restart animation todo: remove ugly hack and reimplement with pure ELM
    // app.ports.runAnimation.subscribe(() => {
    //     const el = document.querySelector(".run-animation")
    //     if (el === null) return
    //     el.classList.remove("run-animation")
    //     void el.offsetWidth
    //     el.classList.add("run-animation")
    // })

    // scroll to element
    app.ports.scroll.subscribe(domId => {
        document.getElementById(domId)?.scrollIntoView()
    })

    // scroll to top
    app.ports.scrollToTop.subscribe(domId => {
        window.scrollTo(0, 0);
    })

    app.ports.closeDucks.subscribe(() => {
        localStorage.setItem(navBarLocalStorageItem, Date.now().toString())
    })

    app.ports.getItem.subscribe(key => {
        app.ports.gotItem.send({key, value: localStorage.getItem(key) ?? null})
    })

    app.ports.setItem.subscribe(params => {
        localStorage.setItem(params.key, params.value)
    })

    app.ports.broadcastError.subscribe(app.ports.error.send)

    addressValidation.init(app.ports, config.chainId);
    governance.init(app.ports);
    assetModule.init(app.ports);

    function escClicked() {
        document.querySelector("[data-key=escape]")?.click()
    }


    // JS hacks: Bind Enter to submit button
    document.addEventListener("keyup", event => {
        switch (event.key) {
            case "Enter":
                if (event?.target?.type === "textarea") return;
                const btn = document.querySelector("button[type=submit]")
                if (btn === null || btn.disabled) return
                btn.click()
                event.preventDefault()
                break

            case "Escape":
                escClicked()
                event.preventDefault()
                break

        }
    })

    document.addEventListener("click", event => {
        if (event.target?.dataset.role === "cancel") {
            escClicked()
        }
    })

    function sendWidth() {
        app.ports.gotWidth.send(window.innerWidth)
    }

    // app.ports.getWidth.subscribe(sendWidth)

    window.onresize = sendWidth;

})


// https://nodes.wavesnodes.com/assets/balance/3PJmNd7oXYHqDD92xYH4f6zxNxEV45togP3

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()